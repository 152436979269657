import '../pug/static/header/burger/burger'

addEventListener('resize', () => {
	const windowInnerWidth = window.innerWidth - 167;
	document.documentElement.style.setProperty('--position', windowInnerWidth + 'px')
})

import '../pug/includes/popup/modal'

const headerBtnSearch = document.querySelector('.header__btn-search');
const headerSearch = document.querySelector('.menu__item');
const searchBtn = document.querySelector('.search__btn');
const body = document.querySelector('body');

document.addEventListener('click', (e) => {
	const targetElement = e.target;

	if (targetElement.classList.contains('menu__link-icon')) {
		targetElement.closest('.menu__item').classList.toggle('active');
	}
	if (!targetElement.closest('.menu__item') && document.querySelectorAll('.menu__item.active').length > 0) {
		_removeClasses(document.querySelectorAll('.menu__item.active'), 'active');
	}
})

function _removeClasses(el, class_name) {
	for (var i = 0; i < el.length; i++) {
		el[i].classList.remove(class_name);
	}
}
